import { $t } from '@/i18n';
import useValidate from '@/use/validate';
import { submitFinalStep } from '@/handlers/shared';

const {
  isEmail,
  isPassword,
} = useValidate();

export default () => ({
  id: 'developer',

  fallbackFlowId: process.env.VUE_APP_DEFAULT_FLOW,

  eloqua: {
    formName: '8534_DomoDeveloperSandboxRequest',
    campaignId: '9595',
  },

  steps: [
    {
      id: 'home',
      routeName: null,
      component: {
        path: 'views/shared/HomeView',
      },
      isPublic: true,
      headerTheme: 'blue',
      saveSessionOnSubmit: false,
      analyticsMetaFields: {
        eloquaFormName: $t('flows.developer.steps.home.eloquaFormName'),
      },
      fields: [
        {
          name: 'email',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.businessEmail'),
          validatorClientSide: ({ email }) => isEmail(email.value),
        },
      ],
    },

    {
      id: 'email',
      routeName: 'email',
      component: {
        path: 'views/shared/EmailView',
      },
      isPublic: true,
      saveSessionOnSubmit: false,
      analyticsMetaFields: {
        eloquaFormName: $t('flows.developer.steps.home.eloquaFormName'),
      },
      fields: [
        {
          name: 'email',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.businessEmail'),
          validatorClientSide: ({ email }) => isEmail(email.value),
        },
      ],
    },

    {
      id: 'confirm',
      routeName: 'confirm',
      component: {
        path: 'views/shared/ConfirmView',
      },
      isPublic: true,
      saveSessionOnSubmit: true,
      fields: [
        {
          name: 'code',
          value: [],
          defaultValue: [],
          isOptional: true,
          omitFromSession: true,
        },
      ],
    },

    {
      id: 'profile',
      routeName: 'profile',
      component: {
        path: 'views/shared/ProfileView',
      },
      saveSessionOnSubmit: true,
      fields: [
        {
          name: 'firstName',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.firstName'),
        },
        {
          name: 'lastName',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.lastName'),
        },
        {
          name: 'password',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.password'),
          omitFromSession: true,
          validatorClientSide: ({ password }) => isPassword(password.value),
        },
        {
          name: 'country',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.country'),
          isTrackedByAnalytics: true,
        },
        {
          name: 'consent',
          value: null,
          defaultValue: null,
          isOptional: true,
        },
        {
          name: 'agreeToTerms',
          value: false,
          defaultValue: false,
          validatorClientSide: ({ agreeToTerms }) => agreeToTerms.value === true,
        },
      ],
    },

    {
      id: 'personal',
      routeName: 'personal',
      component: {
        path: 'views/shared/PersonalView',
      },
      saveSessionOnSubmit: true,
      submitHandler: submitFinalStep,
      fields: [
        {
          name: 'department',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ departmentOther }) => !!departmentOther.value,
        },
        {
          name: 'departmentOther',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
          isOptionalIf: ({ department }) => !!department.value,
        },
        {
          name: 'jobTitle',
          value: '',
          defaultValue: '',
          isTrackedByAnalytics: true,
        },
      ],
    },
  ],
});
